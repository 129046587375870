import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import {
  ApiConfigDTO,
  BookingDTO,
  DailyAvailabilityDTO,
  MonthlyAvailabilityDTO,
} from 'src/app/shared/dtos';
import {
  ApiConfigMapper,
  BookingMapper,
  DailyAvailabilityMapper,
  MonthlyAvailabilityMapper,
} from 'src/app/shared/mappers';
import {
  DAILY_AVAILABILITY_MOCK,
  MONTHLY_AVAILABILITY_MOCK,
} from 'src/app/shared/mocks';
import {
  ApiConfig,
  Booking,
  BookingType,
  DailyAvailability,
  MonthlyAvailability,
} from 'src/app/shared/models';
import { environment } from 'src/environments/environment';
import { TemplateCalendar, TemplatesMonthInfo } from '../models/TemplatesMonthInfo';

const httpOptionsBase = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

export type Month = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export function isMonth(value: number): value is Month {
  return value >= 1 && value <= 12;
}

interface AvailabilityBookingsByMonthFilters {
  reserve_type: BookingType;
  visite_size: number;
  month: Month;
  year: number;
}
interface AvailabilityBookingsByDayFilters
  extends AvailabilityBookingsByMonthFilters {
  day: number;
}

interface BookingParams {
  user_id: string;
  visita_id: number;
  num_visitor: number;
}

interface UserBookingParams {
  user_id: string;
}

interface UserDataKpisParams {
  user_id: string;
  pais: string;
  edad: string;
  codigo_postal: string;
  sexo: string;
}

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  private readonly apiUrl: string = environment.apiUrl;

  private readonly apiConfigSubject = new BehaviorSubject<ApiConfig | null>(
    null,
  );
  readonly apiConfig$ = this.apiConfigSubject.asObservable();

  set apiConfig(newApiConfig: ApiConfig | null) {
    this.apiConfigSubject.next(newApiConfig);
  }

  get apiConfig(): ApiConfig | null {
    return this.apiConfigSubject.getValue();
  }

  constructor(private http: HttpClient) {
    this.getApiConfig();
  }

  async getApiConfig(): Promise<void> {
    const url = `${this.apiUrl}/config`;
    const response = await lastValueFrom(this.http.get<ApiConfigDTO>(url));
    this.apiConfig = ApiConfigMapper.fromDto(response);
  }

  getTimetables() {
    return this.http.get(`${this.apiUrl}/config`)
  }

  getBookings() {
    return this.http.get(`${this.apiUrl}/adminreservas`);
  }

  async hasActiveBooking(filters: UserBookingParams): Promise<boolean> {
    const url = `${this.apiUrl}/reservas/active`;
    const { user_id } = filters;
    const params = {
      user_id,
    };
    const { hasActiveReservation } = await lastValueFrom(
      this.http.get<{ message: string[]; hasActiveReservation: boolean }>(url, {
        params,
      }),
    );

    return hasActiveReservation;
  }

  async postUserDataKpis(data: UserDataKpisParams): Promise<boolean> {
    const url = `${this.apiUrl}/kpis/user_data_kpis`;

    // Realiza la solicitud POST enviando los datos en el cuerpo
    const { success } = await lastValueFrom(
      this.http.post<{ message: string[]; success: boolean }>(url, data)
    );

    return success;
  }

  async getRecentBooking(filters: UserBookingParams): Promise<Booking> {
    const { user_id } = filters;
    const url = `${this.apiUrl}/reservas/user/${user_id}`;

    const response = await lastValueFrom(this.http.get<BookingDTO>(url));

    return BookingMapper.fromDto(response);
  }

  async getUsedTemplatesByMonth(month: number, year: number): Promise<TemplatesMonthInfo> {
    const url = `${this.apiUrl}/templates_of_month`;
    const body = {
      month,
      year
    }
    const response = await lastValueFrom(
      this.http.post<TemplateCalendar[]>(url, body)
    );
    let templates: TemplatesMonthInfo = TemplatesMonthInfo.create(response);
    return { templates: response };
  }

  async getAvailableBookingsByMonth(
    filters: AvailabilityBookingsByMonthFilters,
  ): Promise<MonthlyAvailability[]> {
    const url = `${this.apiUrl}/reservas/month`;
    const { reserve_type, visite_size, month, year } = filters;
    const params = {
      reserve_type,
      visite_size,
      month,
      year,
    };

    if (!environment.useMocks) {
      const response: MonthlyAvailabilityDTO[] = await lastValueFrom(
        this.http.get<MonthlyAvailabilityDTO[]>(url, { params }),
      );
      return MonthlyAvailabilityMapper.arrayFromDto(response);
    } else {
      return MonthlyAvailabilityMapper.arrayFromDto(MONTHLY_AVAILABILITY_MOCK);
    }
  }

  async getAvailableBookingsByDay(
    filters: AvailabilityBookingsByDayFilters,
  ): Promise<DailyAvailability[]> {
    const url = `${this.apiUrl}/reservas/day`;
    const { reserve_type, visite_size, day, month, year } = filters;
    const params = {
      reserve_type,
      visite_size,
      day,
      month,
      year,
    };

    if (!environment.useMocks) {
      const response: DailyAvailabilityDTO[] = await lastValueFrom(
        this.http.get<DailyAvailabilityDTO[]>(url, { params }),
      );
      return DailyAvailabilityMapper.arrayFromDto(response);
    } else {
      return DailyAvailabilityMapper.arrayFromDto(DAILY_AVAILABILITY_MOCK);
    }
  }

  async createBooking(props: BookingParams): Promise<void> {
    const url = `${this.apiUrl}/reservas`;
    const { user_id, num_visitor, visita_id } = props;
    const body = {
      user_id,
      num_visitor,
      visita_id,
    };
    await lastValueFrom(this.http.post(url, body));
  }

  createAdminBooking(body: any) {
    return this.http.post(`${this.apiUrl}/reservainterna`, body)
  }

  async getBookingByUserId(userId: string): Promise<Booking> {
    const url = `${this.apiUrl}/reservas/user/${userId}`;

    const response = await lastValueFrom(this.http.get<BookingDTO>(url));

    return BookingMapper.fromDto(response);
  }

  async updateBooking(code: string, numVisitors: number): Promise<void> {
    const url = `${this.apiUrl}/reservas/${code}`;

    await lastValueFrom(this.http.put(url, { num_visitor: numVisitors }));
  }

  deleteBooking(bookingId: string) {
    return this.http.delete(`${this.apiUrl}/reservas/${bookingId}`)
  }

  deleteBookingAdmin(bookingId: number, body: any) {
    return this.http.delete(`${this.apiUrl}/adminreservas/${bookingId}`, body)
  }

  deleteBookingAdminWithReason(body: any) {
    return this.http.post(`${this.apiUrl}/deleteadminreservas/`, body)
  }

  updateSchedule(body: any) {
    return this.http.patch(`${this.apiUrl}/schedule`, body)
  }
}
