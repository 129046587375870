<section class="bookings-page d-flex h-100" id="bookings-page">
  <div class="container">
    <div class="row g-0 text-center">
      <app-title></app-title>

      <p class="d-block mb-0 text-white font-Lato-light text-xs animated" *ngIf="hasActiveBooking">
        <b>Para disfrutar de la experiencia, recomendamos <a style="color: #ec288b" href="https://play.google.com/store/apps/details?id=es.madrid.MADIGMVANDMIG">descargar la aplicación
          </a>antes de ir a las instalaciones</b>
        <br>
        {{ activeBooking?.getActiveReservationMessage() }}
      </p>
    </div>

    <div class="row justify-content-center g-0 mt-3 booking-type-selectors">
      <div class="col-12 col-lg-5 custom-column tablet">
        <h2 class="text-white font-Lato-light text-xxs noted">
          Elige el tipo de pase
        </h2>
        <div class="number-input-container" [ngClass]="{ disabled: specialQuantity > 0 }">
          <label class="text-white font-Lato-regular text-xxs" for="quantityGeneral">
            Pase general
          </label>
          <div class="input-group">
            <button class="btn-transparent btn-decrement" (click)="decrementQuantity(QuantityType.General)"
              [disabled]="specialQuantity > 0">
              <span class="d-flex">
                <img src="assets/images/btn_menos.svg" alt="Menos" />
              </span>
            </button>
            <input class="text-sm font-Lato-bold" type="number" id="quantityGeneral" min="0"
              [max]="apiConfig?.generalReservationLimit ?? 0" [(ngModel)]="generalQuantity"
              (change)="updateQuantity(generalQuantity, QuantityType.General)" [disabled]="specialQuantity > 0" />
            <button class="btn-transparent btn-increment" (click)="incrementQuantity(QuantityType.General)" [disabled]="
                specialQuantity > 0 ||
                generalQuantity === apiConfig?.generalReservationLimit
              ">
              <span class="d-flex">
                <img src="assets/images/btn_mas.svg" alt="Mas" />
              </span>
            </button>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-5 custom-column tablet align-content-end">
        <div class="number-input-container" [ngClass]="{ disabled: generalQuantity > 0 }">
          <label class="text-white font-Lato-regular text-xxs" for="quantitySpecial">Pase especial(*)</label>
          <div class="input-group">
            <button class="btn-transparent btn-decrement" (click)="decrementQuantity(QuantityType.Special)"
              [disabled]="generalQuantity > 0">
              <span class="d-flex">
                <img src="assets/images/btn_menos.svg" alt="Menos" />
              </span>
            </button>
            <input class="text-sm font-Lato-bold" type="number" id="quantitySpecial" min="0"
              [max]="apiConfig?.groupReservationLimit ?? 0" [(ngModel)]="specialQuantity"
              (change)="updateQuantity(specialQuantity, QuantityType.Special)" [disabled]="generalQuantity > 0" />
            <button class="btn-transparent btn-increment" (click)="incrementQuantity(QuantityType.Special)" [disabled]="
                generalQuantity > 0 ||
                specialQuantity === apiConfig?.groupReservationLimit
              ">
              <span class="d-flex">
                <img src="assets/images/btn_mas.svg" alt="Mas" />
              </span>
            </button>
          </div>
          <p class="note text-white font-Lato-light text-xxs mb-0">
            (*) Pase reservado para colegios, asociaciones, institutos, etc
          </p>
        </div>
      </div>
    </div>

    <div class="row g-0 justify-content-center continue-block" [attr.inert]="hasCalendarData ? true : null"
      *ngIf="!hasCalendarData">
      <div class="button-triangle-wrapper w-auto">
        <button class="button" (click)="getCalendarData()" [disabled]="generalQuantity === 0 && specialQuantity === 0">
          Continuar
        </button>
      </div>
    </div>

    <div class="row justify-content-center g-0 calendar-row" [hidden]="!hasCalendarData" #pickTheDate>
      <div class="col-12 col-lg-5 custom-column animated">
        <div class="calendar">
          <h2 class="text-white font-Lato-light text-xxs noted">
            Elige el día
          </h2>
        </div>

        <ngb-datepicker *ngIf="hasCalendarData" #dp [(ngModel)]="model" (navigate)="date = $event.next"
          [outsideDays]="outsideDays" [navigation]="navigation" class="custom-date-picker"
          [contentTemplate]="datePickerCustom" [dayTemplate]="customDayTemplate"
          (dateSelect)="getAvailableTimes(model)" />

        <ng-template #datePickerCustom let-dp>
          <div class="date-picker-template">
            <div class="btn-group d-flex justify-content-between mb-2" role="group">
              <button type="button" class="btn btn-sm btn-transparent flex-0" (click)="navigate(dp, -1)">
                <span class="d-flex">
                  <img src="assets/images/arrowL.svg" alt="Anterior" />
                </span>
              </button>
              <div class="text-white calendar-month font-Lato-bold text-lg align-self-center">
                {{ dp.i18n.getMonthShortName(dp.state.focusedDate.month) }}
                {{ dp.state.focusedDate.year }}
              </div>
              <button type="button" class="btn btn-sm btn-transparent flex-0" (click)="navigate(dp, 1)">
                <span class="d-flex">
                  <img src="assets/images/arrowR.svg" alt="Siguiente" />
                </span>
              </button>
            </div>
            <div class="custom-month-grid">
              <div *ngFor="let month of dp.state.months; trackBy: trackByMonth">
                <div>
                  <ngb-datepicker-month class="rounded" [month]="month"></ngb-datepicker-month>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template #customDayTemplate let-date="date" let-currentMonth="currentMonth" let-selected="selected">
          <span class="custom-day" [class.available]="isAvailable(date)"
            [class.low-availability]="isLowAvailability(date)" [class.selected]="selected"
            [class.disabled]="!isAvailable(date) && !isLowAvailability(date)">
            {{ date.day }}
          </span>
        </ng-template>
      </div>
      <div class="col-12 col-lg-5 custom-column" [ngClass]="{
          invisible: morningData.length === 0 && afternoonData.length === 0
        }">
        <div class="animated d-flex flex-column h-100" *ngIf="morningData.length > 0 || afternoonData.length > 0">
          <div class="calendar-dates" #pickTheTimes>
            <h2 class="text-white font-Lato-light text-xxs noted">
              Franja Horaria
            </h2>
          </div>

          <div class="d-flex gap-3">
            <button class="btn button-border-left font-Lato-regular text-xxs w-100"
              (click)="showTimeSlots(TimeSlot.morning)" [disabled]="morningData.length === 0"
              [ngClass]="{ active: timeSlotSelected === TimeSlot.morning }">
              Mañana
            </button>
            <button class="btn button-border-left font-Lato-regular text-xxs w-100"
              (click)="showTimeSlots(TimeSlot.afternoon)" [disabled]="afternoonData.length === 0"
              [ngClass]="{ active: timeSlotSelected === TimeSlot.afternoon }">
              Tarde
            </button>
          </div>

          <div class="times animated" *ngIf="timeSlotSelected" #timeSlots>
            <h2 class="text-white font-Lato-light text-xxs noted">
              Horario disponible
            </h2>
          </div>

          <div class="row animated" *ngIf="timeSlotSelected">
            <div class="col-4 col-sm-3 mb-2 pb-1" *ngFor="
                let slot of timeSlotSelected === TimeSlot.morning
                  ? morningData
                  : afternoonData
              ">
              <button class="btn button-border-left font-Lato-regular text-sm w-100 text-nowrap time-btn"
                (click)="selectSlotData(slot)" [ngClass]="{ selected: slot.visitId === selectedTime?.visitId }">
                {{ slot.date | date : 'HH:mm' }} h
              </button>
            </div>
          </div>

          <div class="d-flex flex-column mt-auto animated politics" *ngIf="timeSlotSelected">
            <div class="row align-self-center mt-3">
              <label class="text-white font-Lato-light text-xxxs">
                <input type="checkbox" [(ngModel)]="isAccepted" />
                <p class="text ms-2 mb-0">
                  Acepto los
                  <span class="text-decoration-underline font-Lato-regular" (click)="openPrivacyPolicy()">
                    términos y condiciones.
                  </span>
                </p>
              </label>
            </div>
            <div class="row g-0 align-self-center mt-3">
              <div class="button-triangle-wrapper">
                <button class="button text-nowrap" (click)="bookSlot(confirmBooking)" [disabled]="
                    !isAccepted ||
                    !selectedTime ||
                    (generalQuantity === 0 && specialQuantity === 0) ||
                    hasActiveBooking
                  ">
                  Reservar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #privacyPolicy let-modal>
  <div class="modal-header">
    <button type="button" class="btn-close btn-close-white" aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <h3 class="font-Lato-regular text-xl text-white d-flex align-items-center justify-content-center mb-3">
      Términos y condiciones
    </h3>
    <div class="content">
      <p class="font-Lato-regular text-xs text-white">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
        dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
        illo inventore veritatis et quasi architecto beatae vitae dicta sunt
        explicabo.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
        fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem
        sequi nesciunt.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
        consectetur, adipisci velit, sed quia non numquam eius modi tempora
        incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim
        ad minima veniam, quis nostrum exercitationem ullam corporis suscipit
        laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel
        eum iure reprehenderit qui in ea voluptate velit esse quam nihil
        molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas
        nulla pariatur?
      </p>
      <p class="font-Lato-regular text-xs text-white">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
        dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
        illo inventore veritatis et quasi architecto beatae vitae dicta sunt
        explicabo.
      </p>
    </div>
  </div>
</ng-template>

<ng-template #confirmBooking let-modal>
  <div class="modal-body confirm">
    <h4 class="font-Lato-regular text-xl text-white text-center mb-3">
      {{ getQuantityText() }}
    </h4>
    <p class="font-Lato-light text-xs text-white text-center">
      {{ selectedTime?.date | date : 'EEEE d, MMMM yyyy' }},
      <span class="bold-date font-Lato-bold">
        {{ selectedTime?.date | date : 'HH:mm' }} h
      </span>
    </p>
    <div class="row g-0 justify-content-center mt-3 mb-2">
      <div class="button-triangle-wrapper w-auto">
        <button class="button text-nowrap w-auto" (click)="modal.close()">
          Continuar
        </button>
      </div>
    </div>
  </div>
</ng-template>
