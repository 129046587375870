<h1 class="p-3 p-xxl-5">\\DASHBOARD</h1>
<div class="d-flex flex-wrap align-items-start p-5 pt-0 w-100 h-100 overflow-hidden">
  <div class="d-flex flex-column w-50 animated h-100 overflow-hidden">
    <h2 class="text-white subtitle noted mb-3 width-fit-content">
      Calendario
    </h2>
    <app-calendar *ngIf="currentSection == 0" [extraPadding]="true" [seeColors]="true" [showLegend]="true"
      [showFooter]="false" [showDay]="true" [showClose]="false" [outsideDays]="'collapsed'"
      (datePicked)="datePicked($event)" class="overflow-auto pe-2 h-100"></app-calendar>
  </div>

  <div class="d-flex flex-column w-50 justify-content-left booking-type-selectors ps-5 h-100 overflow-hidden">
    <h2 class="text-white subtitle noted width-fit-content">
      Experiencias Activas
    </h2>
    <div class="width-fit-content">
      <div *ngIf="(visits | filterByStatus: 'InProgress')?.length === 0"
        class="text-white font-Lato-regular text-xxs p-2 pb-5">
        No hay experiencias activas.
      </div>
      <div class="scroll-container overflow-auto w-100 h-100">
        <div *ngFor="let visit of visits | filterByStatus: 'InProgress'"
          class="border-custom number-input-container mb-4">
          <label class="text-white font-Lato-regular text-xxs p-3">
            {{ visit.date | date: 'HH:mm' }}h
          </label>
          <label class="text-green font-Lato-regular text-xxs">
            InProgress
          </label>
          <label class="text-white font-Lato-regular text-xxs">
            {{ visit.reserve_type == 'particular' ? 'Pase General' : 'Pase Especial' }}
          </label>
          <label class="text-white font-Lato-regular text-xxs p-3">
            {{ visit.num_users }} / {{ visit.visite_size }}
          </label>
        </div>
      </div>
    </div>

    <h2 class="text-white subtitle noted width-fit-content">
      Próximas Experiencias
    </h2>
    <div *ngIf="(visits | filterByStatus: 'waiting')?.length === 0" class="text-white font-Lato-regular text-xxs p-2">
      No hay próximas experiencias.
    </div>
    <div class="scroll-container overflow-auto w-100 h-100">
      <div *ngFor="let visit of visits | filterByStatus: 'waiting'"
        class=" d-flex border-custom w-100 mb-4 justify-content-between align-items-center">
        <label class="text-white font-Lato-regular text-xxs p-3">
          {{ visit.date | date: 'HH:mm' }}h
        </label>
        <label class="text-green font-Lato-regular text-xxs">
          {{ visit.current_activity }}
        </label>
        <label class="text-white font-Lato-regular text-xxs">
          {{ visit.reserve_type === 'particular' ? 'Pase General' : 'Pase Especial' }}
        </label>
        <label class="text-white font-Lato-regular text-xxs p-3">
          {{ visit.num_users }} / {{ visit.visite_size }}
        </label>
      </div>
    </div>
  </div>

  <ng-template #noVisits>
    <div class="no-visits-text text-white">No hay visitas para hoy.</div>
  </ng-template>


