import { Component, OnInit } from '@angular/core';
import { BookingService } from '../shared/services';
import { environment } from 'src/environments/environment.dev';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  private readonly apiUrl: string = environment.apiUrl;
  collective: string = ""
  general: string = ""

  constructor(
    private service: BookingService
  ) {

  }

  ngOnInit() {
    this.service.getTimetables().subscribe((data: any) => {
      this.general = data.general_schedule
      this.collective = data.collective_schedule
    })
  }
}
