import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BookingService } from 'src/app/shared/services';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
})
export class DashboardLayoutComponent implements OnInit, OnDestroy {
  private subscriptions!: Subscription;
  general: string = "";
  collective: string = "";
  constructor(private router: Router, private service: BookingService) {}

  ngOnInit(): void {
    this.subscriptions = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const mainContent = document.getElementById('body');
        mainContent?.scrollTo({ top: 0, behavior: 'smooth' });
      }
    });

    this.service.getTimetables().subscribe((data: any) => {
      this.general = data.general_schedule;
      this.collective = data.collective_schedule;
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
