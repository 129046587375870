import { Component, Input, ViewChild } from '@angular/core';
import { AuthService, BookingService } from 'src/app/shared/services';
import { AdminBookingComponent } from 'src/app/admin/admin-booking/admin-booking.component';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/shared/models';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})

export class AdminHomeComponent {
  @ViewChild(AdminBookingComponent) bookings!: AdminBookingComponent
  @Input() currentSection: number = 0
  bookingId: number = 0;
  bookingIdOld: number = 0;
  code: string = "";
  userRole: string = "";
  reason: string = '';


  constructor(
    private bookingService: BookingService,
    private authService: AuthService,
    private toastService: ToastrService
  ) {
    this.authService.user$.subscribe((userLogged: User | null) => this.userRole = userLogged?.role || "");
  }

  setCurrentSection(section: number) {
    let aux = section
    this.currentSection = -1;
    setTimeout(() => this.currentSection = aux, 250);
  }

  popupWindowOpen(parameters: any[]) {
    this.bookingId = parameters[0];
    this.bookingIdOld = parameters[0];
    this.code = parameters[1]
  }

  isTextareaEmpty(): boolean {
    return this.reason.trim() === '';
  }



  popupCancel() {
    this.bookingId = 0;
  }

  popupConfirm() {
    let body = {
      code: this.code,
      motivo: this.reason,
      id: this.bookingId
    }
    this.bookingService.deleteBookingAdminWithReason(body).subscribe(
      (data: any) => {
        this.bookingId = 0;
        this.bookings.refreshBookings();
        this.showSuccessToast("Reserva eliminada correctamente")
        this.reason = ''
      }, (error) => {
        console.error(error);
        this.showErrorToast(error)
      }
    )
  }

  showSuccessToast(message: string) {
    this.toastService.success("", message, {
      toastClass: 'ngx-toastr toast-success',
      progressBar: true,
      timeOut: 3000
    });
  }

  showErrorToast(error: any) {
    this.toastService.error(error.error, 'Error', {
      toastClass: 'ngx-toastr toast-error',
      progressBar: true,
      timeOut: 3000
    });
  }
}
