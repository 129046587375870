<h1 class="p-3 p-xxl-5 mb-0">\\Horario</h1>
<div class="d-flex w-100 h-100 justify-content-center gap-4 overflow-hidden pb-5 px-5">
    <div class="d-flex flex-column overflow-auto pe-2 custom-width">
        <div class="custom-form custom-inputs d-flex flex-column gap-3 w-100 h-100 p-5" *ngIf="configForm"
            [formGroup]="configForm">
            <div class="text-center">
                <h4>Modificar Horario</h4>
                <img *ngIf="actionType == 0" src="assets/edit.svg" alt="edit" (click)="changeActionType(1)">
                <img *ngIf="actionType == 1" src="assets/images/btn_close.svg" alt="close"
                    (click)="changeActionType(0)">
            </div>

            <h5>General</h5>
            <div class="input-container gap-3">
                <div class="input w-25">
                    <p>Max personas</p>
                    <input type="number" formControlName="genMaxPeople" appRemoveLeadingZero>
                </div>
            </div>

            <div class="input-container d-flex gap-3 pb-4">
                <div class="input position-relative">
                    <p>Horario</p>
                    <textarea placeholder="Notas sobre esta experiencia Outdoor"
                        formControlName="genTimetable"></textarea>
                </div>
            </div>

            <h5>Especial</h5>
            <div class="input-container gap-3">
                <div class="input w-25">
                    <p>Max personas</p>
                    <input type="number" formControlName="espMaxPeople" appRemoveLeadingZero>
                </div>
            </div>

            <div class="input-container d-flex gap-3">
                <div class="input position-relative">
                    <p>Horario</p>
                    <textarea placeholder="Notas sobre esta experiencia Outdoor"
                        formControlName="espTimetable"></textarea>
                </div>
            </div>
            <div class="input-container justify-content-around mt-auto">
                <div class="button-triangle-wrapper w-auto">
                    <button class="button" type="submit" id="cancelBtn" (click)="changeActionType(0)"
                        [disabled]="actionType == 0 ? 'true' : null">Cancelar</button>
                </div>
                <div class="button-triangle-wrapper w-auto">
                    <button class="button" type="submit" id="confirmBtn" (click)="submitTimetable()"
                        [disabled]="actionType == 0 || !configForm.valid ? 'true' : null">Modificar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="w-100">
        a
    </div>
</div>
