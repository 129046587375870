import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { ConfigService } from 'src/app/shared/services/config.service';
import { ToastrService } from 'ngx-toastr';
import { BookingService } from 'src/app/shared/services';

@Component({
  selector: 'app-admin-config',
  templateUrl: './admin-config.component.html',
  styleUrls: ['./admin-config.component.scss']
})
export class AdminConfigComponent implements OnInit, OnChanges {

  @Input() currentSection: number = 0;
  divSelector: number = 0;
  sideDayPopup: boolean = false;
  isAddVisit: boolean = true;
  isAddBooking: boolean = false;
  editDayForm: any;
  popupForm: any;
  days: string[] = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
  months: string[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  visitEditSelected: any;
  dateSelected: Date = new Date();
  templateSelected: number = 0;
  newVisitDate: Date = new Date();
  weekDay: string = this.days[this.dateSelected.getDay()];
  day: string = String(this.dateSelected.getDate()).padStart(2, '0');
  month: string = this.months[this.dateSelected.getMonth()];
  dailyVisits: any = [];
  dailyVisitsMorning: any = [];
  dailyVisitsAfternoon: any = [];
  morningSelected: boolean = true;
  templateId: number = 0
  daysToTemplate: string[] = []
  seeCalendar: boolean = true;
  confirmationPopup: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private configService: ConfigService,
    private toastService: ToastrService,
    private bookingService: BookingService
  ) { }

  ngOnInit(): void {
    this.initForms();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["currentSection"] && changes["currentSection"].currentValue == 2) this.initDailyVisit();
  }

  initForms() {
    this.editDayForm = this.formBuilder.group({
      tipoVisitaMañana: ['particular', Validators.required],
      horarioMañanaInicio: ['', Validators.required],
      horarioMañanaFinal: ['', Validators.required],
      tiempoVisitasMañana: ['', [Validators.required, this.greaterThanZeroValidator]],
      personasVisitaMañanaInicio: ['', Validators.required],
      personasVisitaMañanaFinal: ['', Validators.required],
      tipoVisitaTarde: ['particular', Validators.required],
      horarioTardeInicio: ['', Validators.required],
      horarioTardeFinal: ['', Validators.required],
      tiempoVisitasTarde: ['', [Validators.required, this.greaterThanZeroValidator]],
      personasVisitaTardeInicio: ['', Validators.required],
      personasVisitaTardeFinal: ['', Validators.required],
    })

    if (this.isAddVisit) {
      this.popupForm = this.formBuilder.group({
        timeVisit: [''],
        idBooking: [''],
        visitType: ['particular'],
        visitTime: [''],
        usersPerGroup: [''],
        maxUsers: [''],
        franjaHoraria: ['']
      })
    } else if (this.isAddBooking) {
      let hora = new Date(this.visitEditSelected.date)
      this.popupForm = this.formBuilder.group({
        timeVisit: [{value: hora.getHours().toString().padStart(2, '0') + ":" + hora.getMinutes().toString().padStart(2, '0'), disabled: true}],
        franjaHoraria: [this.visitEditSelected.franja],
        visitName: [''],
        numberAssistants: ['']
      })
    } else {
      let hora = new Date(this.visitEditSelected.date)
      this.popupForm = this.formBuilder.group({
        timeVisit: [hora.getHours().toString().padStart(2, '0') + ":" + hora.getMinutes().toString().padStart(2, '0')],
        idBooking: [''],
        visitType: ['particular'],
        visitTime: [this.visitEditSelected.duration],
        usersPerGroup: [this.visitEditSelected.visite_size],
        maxUsers: [this.visitEditSelected.max_group],
        franjaHoraria: [this.visitEditSelected.franja]
      })
    }
  }

  greaterThanZeroValidator(control: AbstractControl): ValidationErrors | null {
    return control.value > 0 ? null : { notGreaterThanZero: true };
  }

  initDailyVisit(date?: boolean) {
    let body = {
      date: this.dateSelected.getFullYear() + "-" + (this.dateSelected.getMonth() + 1).toString().padStart(2, '0') + "-" + this.dateSelected.getDate().toString().padStart(2, '0'),
    }
    if (date) {
      let newDate = new Date()
      body = {
        date: newDate.getFullYear() + "-" + (newDate.getMonth() + 1).toString().padStart(2, '0') + "-" + newDate.getDate().toString().padStart(2, '0'),
      }
    }
    this.configService.getVisitByDay(body).subscribe((data => {
      this.dailyVisitsFilter(data)
    }))
  }

  changeSplit(split: boolean) {
    if (split) {
      this.morningSelected = true
      this.dailyVisits = this.dailyVisitsMorning;
    } else {
      this.morningSelected = false
      this.dailyVisits = this.dailyVisitsAfternoon;
    }
  }

  dailyVisitsFilter(data: any) {
    this.dailyVisitsMorning = []
    this.dailyVisitsAfternoon = []
    data.forEach((dailyVisit: any) => {
      if (dailyVisit.franja === "morning") {
        this.dailyVisitsMorning.push(dailyVisit);
      } else if (dailyVisit.franja === "afternoon") {
        this.dailyVisitsAfternoon.push(dailyVisit);
      }
    });

    this.dailyVisitsMorning.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());
    this.dailyVisitsAfternoon.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());
    this.changeSplit(true);
  }

  editDayFormSubmit() {
    let idTemplate: any = this.templateSelected;
    let botonConfirmarED = <HTMLInputElement>document.getElementById("confirmarED")
    botonConfirmarED.disabled = true;
    botonConfirmarED.innerHTML = "Editando...";

    if (idTemplate == 0) idTemplate = null;
    let body = {
      date: this.dateSelected.getFullYear() + "-" + ((this.dateSelected.getMonth() + 1) + "").padStart(2, '0') + "-" + (this.dateSelected.getDate() + "").padStart(2, '0'),
      id: idTemplate,
      morning_type: this.editDayForm.value.tipoVisitaMañana,
      morning_start_hour: this.editDayForm.value.horarioMañanaInicio + ":00",
      morning_end_time: this.editDayForm.value.horarioMañanaFinal + ":00",
      morning_visit_time: this.editDayForm.value.tiempoVisitasMañana,
      morning_max_people: this.editDayForm.value.personasVisitaMañanaInicio,
      morning_max_group_people: this.editDayForm.value.personasVisitaMañanaFinal,
      afternoon_type: this.editDayForm.value.tipoVisitaTarde,
      afternoon_start_hour: this.editDayForm.value.horarioTardeInicio + ":00",
      afternoon_end_time: this.editDayForm.value.horarioTardeFinal + ":00",
      afternoon_visit_time: this.editDayForm.value.tiempoVisitasTarde,
      afternoon_max_people: this.editDayForm.value.personasVisitaTardeInicio,
      afternoon_max_group_people: this.editDayForm.value.personasVisitaTardeFinal,
    }

    this.configService.modifyDay(body).subscribe(
      (templates: any) => {
        botonConfirmarED.innerHTML = "Creando...";
        this.showSuccessToast("Dia editado correctamente");
        setTimeout(() => {
          botonConfirmarED.innerHTML = "Confirmar";
          botonConfirmarED.disabled = false;
        }, 1000);
      },
      (error) => {
        botonConfirmarED.innerHTML = "Confirmar";
        botonConfirmarED.disabled = false;
        console.error(error);
        this.showErrorToast(error)
      }
    )
  }

  editVisitPopup() {
    let date = new Date(this.visitEditSelected.date)
    date.setHours(this.popupForm.value.timeVisit.substring(0, 2))
    date.setMinutes(this.popupForm.value.timeVisit.substring(3, 5))
    let utcDate = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
    date = utcDate
    let dateBody = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0') + " " + date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0') + ":" + date.getSeconds().toString().padStart(2, '0')

    let body = {
      current_activity: this.visitEditSelected.current_activity,
      current_sala: this.visitEditSelected.current_sala,
      date: dateBody,
      reserve_type: this.popupForm.value.visitType,
      duration: this.popupForm.value.visitTime,
      visite_size: this.popupForm.value.usersPerGroup,
      max_group: this.popupForm.value.maxUsers,
      franja: this.popupForm.value.franjaHoraria,
    }

    this.configService.updateVisita(this.visitEditSelected.id, body).subscribe(
      (data: any) => {
        this.showSuccessToast("Visita editada correctamente")
        this.initDailyVisit()
        this.sideDayPopup = false
      },
      (error) => {
        console.error(error);
        this.showErrorToast(error)
      }
    );
  }

  createVisitPopup() {
    let date = this.newVisitDate
    date.setHours(this.popupForm.value.timeVisit.substring(0, 2))
    date.setMinutes(this.popupForm.value.timeVisit.substring(3, 5))
    let utcDate = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
    date = utcDate
    let dateBody = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0') + " " + date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0') + ":" + date.getSeconds().toString().padStart(2, '0')
    let body = {
      current_activity: "waiting",
      current_sala: null,
      date: dateBody,
      reserve_type: this.popupForm.value.visitType,
      duration: this.popupForm.value.visitTime,
      visite_size: this.popupForm.value.usersPerGroup,
      max_group: this.popupForm.value.maxUsers,
      franja: this.popupForm.value.franjaHoraria
    }
    this.configService.createVisit(body).subscribe(
      (data: any) => {
        this.showSuccessToast("Visita creada correctamente")
        this.initDailyVisit()
        this.sideDayPopup = false
      },
      (error) => {
        console.error(error);
        this.showErrorToast(error)
      }
    );
  }

  createBookingPopup() {
    let body = {
      user_id: "interna",
      visita_id: this.visitEditSelected.id,
      num_visitor: this.visitEditSelected.visite_size,
      intern_visitor: this.popupForm.value.visitName + "- " + this.popupForm.value.numberAssistants
    }

    this.bookingService.createAdminBooking(body).subscribe(
      (data: any) => {
        console.log(data)
        this.showSuccessToast("Reserva creada correctamente");
        this.initDailyVisit();
        this.sideDayPopup = false;
      },
      (error: any) => {
        console.error(error);
        this.showErrorToast(error);
      }
    );
  }


  deleteVisit() {
    this.confirmationPopup = true
  }

  confirmDeleteVisit() {
    this.configService.deleteVisit(this.visitEditSelected.id).subscribe(
      (data: any) => {
        this.showSuccessToast("Visita eliminada correctamente")
        this.initDailyVisit();
        this.sideDayPopup = false;
      },
      (error) => {
        console.error(error);
        this.showErrorToast(error)
      }
    );
    this.confirmationPopup = false;
  }

  datePicked(date: NgbDateStruct) {
    this.changeDivSelector(0);
    this.dateSelected = new Date(date.year + "-" + date.month + "-" + date.day);
    this.weekDay = this.days[this.dateSelected.getDay()]
    this.day = String(this.dateSelected.getDate()).padStart(2, '0')
    this.month = this.months[this.dateSelected.getMonth()];
    this.initDailyVisit()
  }

  addVisit(hour: string) {
    this.newVisitDate = new Date(this.dateSelected.getFullYear(), this.dateSelected.getMonth(), this.dateSelected.getDate(), parseInt(hour.substring(0, 2)), parseInt(hour.substring(3, 5)))
    this.isAddVisit = true;
    this.isAddBooking = false;
    this.sideDayPopup = true;
    this.initForms();
  }

  editVisit(visit: any) {
    this.visitEditSelected = visit;
    this.isAddVisit = false;
    this.isAddBooking = false;
    this.sideDayPopup = true;
    this.initForms()
  }

  addBooking(visit: any) {
    this.visitEditSelected = visit;
    this.isAddVisit = false;
    this.isAddBooking = true;
    this.sideDayPopup = true;
    this.initForms()
  }

  getIdTemplatePicked = (idTemplatePicked: number) => this.templateSelected = idTemplatePicked;

  changeDivSelector = (selector: number) => this.divSelector = selector;

  closeVisit = () => this.sideDayPopup = false

  closeEditCalendar = () => this.divSelector = 0;

  popupFormSubmit() {
    if (this.isAddVisit) {
      this.createVisitPopup();
    } else if (this.isAddBooking) {
      this.createBookingPopup();
    } else {
      this.editVisitPopup();
    }
  }

  showSuccessToast(message: string) {
    this.toastService.success("", message, {
      toastClass: 'ngx-toastr toast-success',
      progressBar: true,
      timeOut: 3000
    });
  }

  showErrorToast(error: any) {
    this.toastService.error(error.error, 'Error', {
      toastClass: 'ngx-toastr toast-error',
      progressBar: true,
      timeOut: 3000
    });
  }

  setIdTemplatePicked = (number: any) => this.templateId = number

  setDaysTemplates = (days: string[]) => this.daysToTemplate = days

  resetConfig() {
    this.seeCalendar = false
    setTimeout(() => this.seeCalendar = true, 100);
  }
}
