<img class="logo" src="assets/logo madridingame station_Mesa de trabajo 1 copia 4.png" alt="logo">
<div>
  <div class="aside-item" (click)="setCurrentSection(0)" [ngClass]="{'active': currentSection == 0}">
    <img src="assets/dashboard.svg" alt="Dashboard" class="aside-icon">
    <span class="aside_text">Dashboard</span>
  </div>
  <div class="aside-item mt-3" (click)="setCurrentSection(1)" [ngClass]="{'active': currentSection == 1}">
    <img src="assets/online-booking.svg" alt="Reservas" class="aside-icon">
    <span class="aside_text">Reservas</span>
  </div>
  <div class="aside-item mt-3" (click)="setCurrentSection(2)" [ngClass]="{'active': currentSection == 2}">
    <img src="assets/config.svg" alt="Configuración" class="aside-icon">
    <span class="aside_text">Configuración</span>
  </div>
  <div class="aside-item mt-3" *ngIf="userRole.toLowerCase() == 'superadmin'" (click)="setCurrentSection(3)"
    [ngClass]="{'active': currentSection == 3}">
    <img src="assets/Grupo 443.svg" alt="Usuarios" class="aside-icon">
    <span class="aside_text">Usuarios</span>
  </div>
  <div class="aside-item mt-3" (click)="setCurrentSection(4)" [ngClass]="{'active': currentSection == 4}">
    <img src="assets/edit-document.svg" alt="Plantillas" class="aside-icon">
    <span class="aside_text">Plantillas</span>
  </div>
  <div class="aside-item mt-3" (click)="setCurrentSection(5)" [ngClass]="{'active': currentSection == 5}">
    <img src="assets/historical.svg" alt="Histórico" class="aside-icon">
    <span class="aside_text">Histórico</span>
  </div>
  <div class="aside-item mt-3" (click)="setCurrentSection(6)" [ngClass]="{'active': currentSection == 6}">
    <img src="assets/images/icon_map_aside.svg" alt="Mapa" class="aside-icon">
    <span class="aside_text">Outdoor</span>
  </div>
  <div class="aside-item mt-3" (click)="setCurrentSection(7)" [ngClass]="{'active': currentSection == 7}">
    <img src="assets/icon_horario.svg" alt="Mapa" class="aside-icon">
    <span class="aside_text">Horario</span>
  </div>
</div>
<div class="container user">
  <div>
    <p>{{ userId }}</p>
  </div>
  <div class="aside-item2 pb-3" (click)="logout()">
    <img src="assets/exit-to-app-button.png" alt="Histórico" class="aside-icon">
    <span class="aside_text2">Cerrar Sesión</span>
  </div>
</div>
