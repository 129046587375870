import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appRemoveLeadingZero]',
  standalone: true
})
export class RemoveLeadingZeroDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const inputElement = this.el.nativeElement;
    let value = inputElement.value;

  // Eliminar el 0
  if (value && value.startsWith('0')) {
    value = value.replace(/^0+/, '');
  }

  // Eliminar signos negativos
  if (value && value.startsWith('-0')) {
    value = '-' + value.replace(/^-\0+/, '');
  }

  inputElement.value = value;
  }
}
