import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BookingService } from 'src/app/shared/services';

@Component({
  selector: 'app-admin-configuration',
  templateUrl: './admin-configuration.component.html',
  styleUrls: ['./admin-configuration.component.scss']
})
export class AdminConfigurationComponent implements OnChanges {
  @Input() currentSection: number = 0
  actionType: number = 0
  previousActionType: number = -1;

  // Form
  configForm: any;
  dataId: number = 0;
  dataGenPeople: number = 0;
  dataGenTT: string = "";
  dataEspPeople: number = 0;
  dataEspTT: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private bookingService: BookingService,
    private toastService: ToastrService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentSection'] && changes['currentSection'].currentValue === 7) {
      if (!this.configForm) {
        this.initForm();
      }
    }
  }

  initForm() {
    this.bookingService.getTimetables().subscribe((data: any) => {
      this.dataId = data.id;
      this.dataGenPeople = data.general_reservation_limit;
      this.dataGenTT = data.general_schedule;
      this.dataEspPeople = data.group_reservation_limit;
      this.dataEspTT = data.collective_schedule;

      this.configForm = this.formBuilder.group({
        genMaxPeople: [this.dataGenPeople, Validators.required],
        genTimetable: [this.dataGenTT],
        espMaxPeople: [this.dataEspPeople, Validators.required],
        espTimetable: [this.dataEspTT]
      })

      if (this.actionType == 0) this.configForm.disable();
    })
  }

  changeActionType(section: number) {
    if (section == 0) {
      this.initForm()
    } else if (section == 1) {
      this.configForm.enable()
    }

    this.actionType = section;
  }

  submitTimetable() {
    let button = <HTMLInputElement>document.getElementById('confirmBtn');
    let body = {
      id: this.dataId,
      general_reservation_limit: this.configForm.value.genMaxPeople,
      group_reservation_limit: this.configForm.value.espMaxPeople,
      general_schedule: this.configForm.value.genTimetable,
      collective_schedule: this.configForm.value.espTimetable
    }

    button.disabled = true;
    button.innerHTML = "Modificando..."

    this.bookingService.updateSchedule(body).subscribe(
      (response) => {
        this.changeActionType(0);
        this.showSuccessToast('Horario modificado correctamente')
        button.innerHTML = "Modificar"
      }, (error) => {
        console.log(error);
        this.showErrorToast(error);
      }
    )
  }

  showSuccessToast(message: string) {
    this.toastService.success("", message, {
      toastClass: 'ngx-toastr toast-success',
      progressBar: true,
      timeOut: 3000
    });
  }

  showErrorToast(error: any) {
    this.toastService.error(error.error, 'Error', {
      toastClass: 'ngx-toastr toast-error',
      progressBar: true,
      timeOut: 3000
    });
  }
}
