export class MapExperience {
    private id: number;
    private lat: number;
    private lon: number;
    private color: string;
    private secondaryColor: string;
    private textColor: string;
    private title: string;
    private description: string;
    private url: string;
    private isPathWay: boolean;
    private experienceNumber: number;

    constructor(
        id: number,
        lat: number,
        lon: number,
        color: string,
        secondaryColor: string,
        textColor: string,
        title: string,
        description: string,
        url: string,
        isPathWay: boolean,
        experienceNumber: number
    ) {
        this.id = id
        this.lat = lat
        this.lon = lon
        this.color = color
        this.secondaryColor = secondaryColor
        this.textColor = textColor
        this.title = title
        this.description = description
        this.url = url
        this.isPathWay = isPathWay
        this.experienceNumber = experienceNumber
    }

    // Getters
    public getId(): number {
        return this.id;
    }

    public getLat(): number {
        return this.lat;
    }

    public getLon(): number {
        return this.lon;
    }

    public getColor(): string {
        return this.color;
    }

    public getSecondaryColor(): string {
        return this.secondaryColor;
    }

    public getTextColor(): string {
        return this.textColor;
    }

    public getTitle(): string {
        return this.title;
    }

    public getDescription(): string {
        return this.description;
    }

    public getUrl(): string {
        return this.url;
    }

    public getIsPathWay(): boolean {
        return this.isPathWay;
    }

    public getExperienceNumber() {
        return this.experienceNumber;
    }

    // Setters
    public setId(id: number): void {
        this.id = id;
    }

    public setLat(lat: number): void {
        this.lat = lat;
    }

    public setLon(lon: number): void {
        this.lon = lon;
    }

    public setColor(color: string): void {
        this.color = color;
    }

    public setSecondaryColor(secondaryColor: string): void {
        this.secondaryColor = secondaryColor;
    }

    public setTextColor(textColor: string): void {
        this.textColor = textColor;
    }

    public setTitle(title: string): void {
        this.title = title;
    }

    public setDescription(description: string): void {
        this.description = description;
    }

    public setUrl(url: string): void {
        this.url = url;
    }

    public setIsPathWay(isPathWay: boolean): void {
        this.isPathWay = isPathWay;
    }

    public setExperienceNumber(experienceNumber: number) {
        this.experienceNumber = experienceNumber;
    }
}
