<div class="w-100 d-flex main_container overflow-hidden">
  <app-admin-aside [currentSection]="currentSection" class="col d-flex flex-column gap-4"
    (currentSectionChange)="setCurrentSection($event)"></app-admin-aside>
  <div class="sections_container col col-10">
    <app-admin-dashboard class="h-100 d-flex flex-column" [ngStyle]="{
      'opacity': currentSection == 0 ? '1' : '0',
      'pointer-events': currentSection == 0 ? 'all': 'none'
    }"></app-admin-dashboard>
    <app-admin-booking [currentSection]="currentSection" (popupWindowOpen)="popupWindowOpen($event)"
      class="h-100 d-flex flex-column" [ngStyle]="{
      'opacity': currentSection == 1 ? '1' : '0',
      'pointer-events': currentSection == 1 ? 'all': 'none'
    }"></app-admin-booking>
    <app-admin-config [currentSection]="currentSection" class="h-100 d-flex flex-column" [ngStyle]="{
      'opacity': currentSection == 2 ? '1' : '0',
      'pointer-events': currentSection == 2 ? 'all': 'none'
    }"></app-admin-config>
    <app-admin-users *ngIf="userRole.toLowerCase() == 'superadmin'" [currentSection]="currentSection"
      class="h-100 d-flex flex-column" [ngStyle]="{
      'opacity': currentSection == 3 ? '1' : '0',
      'pointer-events': currentSection == 3 ? 'all': 'none'
    }"></app-admin-users>
    <app-admin-templates [currentSection]="currentSection" class="h-100 d-flex flex-column" [ngStyle]="{
      'opacity': currentSection == 4 ? '1' : '0',
      'pointer-events': currentSection == 4 ? 'all': 'none'
    }"></app-admin-templates>
    <app-admin-logs [currentSection]="currentSection" class="h-100 d-flex flex-column" [ngStyle]="{
      'opacity': currentSection == 5 ? '1' : '0',
      'pointer-events': currentSection == 5 ? 'all': 'none'
    }"></app-admin-logs>
    <app-admin-map [currentSection]="currentSection" class="h-100 d-flex flex-column" [ngStyle]="{
      'opacity': currentSection == 6 ? '1' : '0',
      'pointer-events': currentSection == 6 ? 'all': 'none'
    }"></app-admin-map>
    <app-admin-configuration [currentSection]="currentSection" class="h-100 d-flex flex-column" [ngStyle]="{
      'opacity': currentSection == 7 ? '1' : '0',
      'pointer-events': currentSection == 7 ? 'all': 'none'
    }"></app-admin-configuration>
  </div>
  <div class="popup_window d-flex justify-content-center align-items-center" [ngStyle]="{
    'opacity': bookingId > 0 ? '1': '0',
    'pointer-events': bookingId > 0 ? 'all': 'none'
  }">
    <div class="popup_content">
      <h3>ESTA SEGURO QUE QUIERE CANCELAR {{ code }}</h3>
      <p>La reserva {{ code }} sera cancelada. ¿Estas seguro que quiere proceder?</p>
      <div class="mb-3">
        <textarea class="input" id="reason" rows="4" cols="50" placeholder="Escriba el motivo aquí..."
          [(ngModel)]="reason"></textarea>
      </div>
      <div>
        <div class="button-triangle-wrapper w-auto"><button class="button" (click)="popupCancel()">Cancelar</button>
        </div>
        <div class="button-triangle-wrapper w-auto"><button class="button" (click)="popupConfirm()"
            [disabled]="isTextareaEmpty()">Confirmar</button>
        </div>
      </div>
    </div>
  </div>
</div>
