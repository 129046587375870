import { Pipe, PipeTransform } from '@angular/core';
import { MapExperience } from '../models/MapExperience';

@Pipe({
  name: 'mapExperienceFilter'
})
export class MapExperienceFilterPipe implements PipeTransform {

  transform(mapExperiences: MapExperience[], filter: string): any[] {
    const filteredExperiences: any[] = [];
 
    if (mapExperiences.length === 0 || filter.length === 0) {
      return mapExperiences;
    }
    const lowerCaseFilter = filter.toLocaleLowerCase();
    mapExperiences.forEach((mapExperience: MapExperience) => {
      let isValid = false;

      if ("senda estelar".includes(lowerCaseFilter) && mapExperience.getIsPathWay() == true) {
        isValid = true;
      }

      if ("otras".includes(lowerCaseFilter) && (mapExperience.getIsPathWay() == false || mapExperience.getIsPathWay() == null)) {
        isValid = true;
      }
      if (mapExperience.getExperienceNumber().toString() === lowerCaseFilter
        || mapExperience.getTitle()?.toLowerCase().includes(lowerCaseFilter)) {
        isValid = true;
      }

      if (isValid) {
        filteredExperiences.push(mapExperience);
      }
    });
 
    return filteredExperiences;
  }
}