import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminAsideComponent } from './admin-aside/admin-aside.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminBookingComponent } from './admin-booking/admin-booking.component';
import { AdminConfigComponent } from './admin-config/admin-config.component';
import { NgbDatepicker, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { AdminTemplatesComponent } from './admin-templates/admin-templates.component';
import { AdminUsersComponent } from './admin-users/admin-users.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarComponent } from './calendar/calendar.component';
import { AdminLogsComponent } from './admin-logs/admin-logs.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { AdminMapComponent } from './map/adminMap.component';
import { RemoveLeadingZeroDirective } from '../shared/directives/remove-leading-zero.directive';
import { AdminConfigurationComponent } from './admin-configuration/admin-configuration.component';



@NgModule({
  declarations: [

    AdminHomeComponent,
    AdminAsideComponent,
    AdminDashboardComponent,
    AdminBookingComponent,
    AdminConfigComponent,
    AdminTemplatesComponent,
    AdminUsersComponent,
    CalendarComponent,
    AdminLogsComponent,
    AdminMapComponent,
    AdminConfigurationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CommonModule,
    NgbDatepicker,
    BrowserModule,
    PipesModule,
    RemoveLeadingZeroDirective,
  ]
})
export class AdminModule { }
