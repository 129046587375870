import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Template } from 'src/app/shared/models/Template';
import { TemplatesService } from 'src/app/shared/services/templates.service';
import { FormBuilder, Validator, ValidationErrors, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-templates',
  templateUrl: './admin-templates.component.html',
  styleUrls: ['./admin-templates.component.scss']
})
export class AdminTemplatesComponent implements OnInit, OnChanges {

  @Input() currentSection: number = 0
  templates: Template[] = [];
  createTemplateForm: any;
  colorBackgroundSelector: string = "#FFFFFF";
  actionType = 0;
  selectedTemplate = -1;
  idSelectedTemplate: number = 0
  popupConfirmation: boolean = false;
  popupConfirmationDelete: boolean = false;
  popupText: string = "";

  constructor(
    private templatesService: TemplatesService,
    private formBuilder: FormBuilder,
    private toastService: ToastrService
  ) { }

  ngOnInit(): void {
    this.initForm()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["currentSection"] && changes["currentSection"].currentValue == 4) this.getTemplates();

  }

  initForm() {
    this.createTemplateForm = this.formBuilder.group({
      idPlantilla: [0],
      nombrePlantilla: ['', Validators.required],
      estado: ['', Validators.required],
      tipoVisitaMañana: ['particular', Validators.required],
      horarioMañanaInicio: ['', Validators.required],
      horarioMañanaFinal: ['', Validators.required],
      tiempoVisitasMañana: ['', Validators.required],
      personasVisitaMañanaInicio: ['', Validators.required],
      personasVisitaMañanaFinal: ['', Validators.required],
      tipoVisitaTarde: ['particular', Validators.required],
      horarioTardeInicio: ['', Validators.required],
      horarioTardeFinal: ['', Validators.required],
      tiempoVisitasTarde: ['', Validators.required],
      personasVisitaTardeInicio: ['', Validators.required],
      personasVisitaTardeFinal: ['', Validators.required],
      colorAsociado: ['', Validators.required],
      nota: ['', Validators.required],
    });
    this.colorBackgroundSelector = "#213948";

  }

  changeActionType(type: number, template: number) {
    // Type: 0 -> crear, 1 -> info, 2 -> editar
    const isSameAction = this.actionType === type && this.selectedTemplate === template;

    this.actionType = isSameAction ? 0 : type;
    this.selectedTemplate = isSameAction ? -1 : template;

    (type === 0 || type === 2) ? this.createTemplateForm.enable() : this.createTemplateForm.disable();
    (this.actionType === 1 || this.actionType === 2) ? this.initValues(template) : this.initForm();
  }

  initValues(template: number) {
    this.createTemplateForm.get('idPlantilla')?.setValue(this.templates[template].getId());

    this.createTemplateForm.get('nombrePlantilla')?.setValue(this.templates[template].getTemplateName());
    this.createTemplateForm.get('estado')?.setValue(this.templates[template].getTemplateStatus());

    this.createTemplateForm.get('tipoVisitaMañana')?.setValue(this.templates[template].getMorningType());
    this.createTemplateForm.get('horarioMañanaInicio')?.setValue(this.templates[template].getMorningStartHour());
    this.createTemplateForm.get('horarioMañanaFinal')?.setValue(this.templates[template].getMorningEndTime());
    this.createTemplateForm.get('tiempoVisitasMañana')?.setValue(this.templates[template].getMorningVisitTime());
    this.createTemplateForm.get('personasVisitaMañanaInicio')?.setValue(this.templates[template].getMorningMaxPeople());
    this.createTemplateForm.get('personasVisitaMañanaFinal')?.setValue(this.templates[template].getMorningMaxGroupPeople());

    // Inicializa los selects de la tarde
    this.createTemplateForm.get('tipoVisitaTarde')?.setValue(this.templates[template].getAfternoonType());
    this.createTemplateForm.get('horarioTardeInicio')?.setValue(this.templates[template].getAfternoonStartHour());
    this.createTemplateForm.get('horarioTardeFinal')?.setValue(this.templates[template].getAfternoonEndTime());
    this.createTemplateForm.get('tiempoVisitasTarde')?.setValue(this.templates[template].getAfternoonVisitTime());
    this.createTemplateForm.get('personasVisitaTardeInicio')?.setValue(this.templates[template].getAfternoonMaxPeople());
    this.createTemplateForm.get('personasVisitaTardeFinal')?.setValue(this.templates[template].getAfternoonMaxGroupPeople());

    // Inicializa el color asociado y la nota
    this.createTemplateForm.get('colorAsociado')?.setValue(this.templates[template].getTemplateColour());
    this.colorBackgroundSelector = this.templates[template].getTemplateColour();
    this.createTemplateForm.get('nota')?.setValue(this.templates[template].getNota());
  }

  openColor() {
    let input = document.getElementById("TemplateColor")
    input?.click();
  }

  colorSelected(event: any) {
    const selectedColor = event.target.value;
    this.createTemplateForm.get('colorAsociado')?.setValue(selectedColor);
    this.colorBackgroundSelector = selectedColor;
  }

  getTemplates(): void {
    this.templatesService.getTemplates().subscribe((templates: any) => {
      this.templates = templates.map((template: any) =>
        new Template(
          template.id,
          template.template_name,
          template.template_status,
          template.morning_type,
          template.morning_start_hour,
          template.morning_end_time,
          template.morning_visit_time,
          template.morning_max_people,
          template.morning_max_group_people,
          template.afternoon_type,
          template.afternoon_start_hour,
          template.afternoon_end_time,
          template.afternoon_visit_time,
          template.afternoon_max_people,
          template.afternoon_max_group_people,
          template.template_colour,
          template.nota
        ))
    });
  }

  submitCreateTemplate(): void {
    let body = {
      "template_name": this.createTemplateForm.value.nombrePlantilla,
      "template_status": this.createTemplateForm.value.estado,
      "morning_type": this.createTemplateForm.value.tipoVisitaMañana,
      "morning_start_hour": this.createTemplateForm.value.horarioMañanaInicio,
      "morning_end_time": this.createTemplateForm.value.horarioMañanaFinal,
      "morning_visit_time": this.createTemplateForm.value.tiempoVisitasMañana,
      "morning_max_people": this.createTemplateForm.value.personasVisitaMañanaInicio,
      "morning_max_group_people": this.createTemplateForm.value.personasVisitaMañanaFinal,
      "afternoon_type": this.createTemplateForm.value.tipoVisitaTarde,
      "afternoon_start_hour": this.createTemplateForm.value.horarioTardeInicio, 
      "afternoon_end_time": this.createTemplateForm.value.horarioTardeFinal,
      "afternoon_visit_time": this.createTemplateForm.value.tiempoVisitasTarde,
      "afternoon_max_people": this.createTemplateForm.value.personasVisitaTardeInicio,
      "afternoon_max_group_people": this.createTemplateForm.value.personasVisitaTardeFinal,
      "template_colour": this.createTemplateForm.value.colorAsociado,
      "nota": this.createTemplateForm.value.nota,
    };
    
    let botonConfirmar = <HTMLInputElement>document.getElementById("confirmar");
    botonConfirmar.disabled = true;

    if (this.actionType == 0) {
      this.templatesService.createTemplate(body).subscribe(
      (templates: any) => {
        botonConfirmar.innerHTML = "Creando...";
        this.popupText = "Plantilla creada correctamente";
        setTimeout(() => {
          botonConfirmar.innerHTML = "Confirmar";
          botonConfirmar.disabled = false;
          this.actionType = 1;
          this.popupConfirmation = true;
        }, 1000);
      }, 
      (error) => {
        botonConfirmar.innerHTML = "Confirmar";
        botonConfirmar.disabled = false;
        console.error(error);
        this.showErrorToast(error)
      }
    )
      

    } else if (this.actionType == 2) {
      this.templatesService.editTemplate(body, this.createTemplateForm.value.idPlantilla).subscribe(
      (templates: any) => {
        botonConfirmar.innerHTML = "Editando..."
        this.popupText = "Plantilla editada correctamente";
        setTimeout(() => {
          botonConfirmar.innerHTML = "Confirmar";
          botonConfirmar.disabled = false;
          this.actionType = 1;
          this.popupConfirmation = true;
        }, 1000);
      }, 
      (error) => {
        botonConfirmar.innerHTML = "Confirmar";
        botonConfirmar.disabled = false;
        console.error(error);
        this.showErrorToast(error)
      })
    }
  }

  deleteTemplate(template: number) {
    this.idSelectedTemplate = this.templates[template].getId();
    this.popupConfirmationDelete  = true;
  }

  confirmDelete() {
    this.popupConfirmationDelete = false;
    this.templatesService.deleteTemplate(this.idSelectedTemplate).subscribe(
      (templates: any) => {
        this.popupConfirmation = true;
        this.popupText = "Plantilla eliminada correctamente";
        this.changeActionType(0, -1)
      }, 
      (error) => {
        console.error(error);
        this.showErrorToast(error)
      })
  }

  confirmPopup() {
    this.getTemplates();
    this.popupConfirmation = false;
  }

  showErrorToast(error: any) {
    this.toastService.error(error.error, 'Error', {
      toastClass: 'ngx-toastr toast-error',
      progressBar: true,
      timeOut: 3000
    });
  }
}
